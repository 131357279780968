<script lang="ts" setup>
import AuthProvider from '~/enums/auth-provider'

const messages = { failedToGetAuthUrl: 'Не удалось получить ссылку на авторизацию.' }

const { $apiHelper, $toast } = useNuxtApp()

const isGettingAuthUrl = ref({ tinkoffId: false, vkId: false, yandexId: false })

async function signIn (authProvider: AuthProvider): Promise<void> {
  isGettingAuthUrl.value[authProvider] = true

  try {
    const response = await $apiHelper.auth.getAuthUrl(authProvider) as any

    window.location.replace(response.meta.authUrl)
  } catch (error) {
    console.error(error)

    $toast.error(messages.failedToGetAuthUrl)
  }

  isGettingAuthUrl.value[authProvider] = false
}
</script>

<template>
  <div>
    <div class="sign-in-text mb16">
      или войти с&nbsp;помощью
    </div>

    <div class="auth-providers">
      <SvgIcon
        v-for="x in AuthProvider.sortedMembers"
        :key="x"
        :class="{ 'is-disabled': isGettingAuthUrl[x] }"
        :name="AuthProvider.getIconName(x)"
        @click="signIn(x)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

.sign-in-text {
  align-items: center;
  color: mix($main-gray-color, white, 60%);
  display: flex;
  justify-content: center;

  &:after,
  &:before {
    background: mix($main-gray-color, white, 10%);
    content: '';
    flex: 1;
    height: 2px;
  }

  &:after {
    margin-left: 1em;
  }

  &:before {
    margin-right: 1em;
  }
}

.auth-providers {
  display: flex;
  justify-content: center;

  svg {
    cursor: pointer;
    font-size: 48px;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }

    &.is-disabled {
      cursor: auto;
      pointer-events: none;
    }
  }
}
</style>
